import React, { Component } from 'react';
import Web3 from 'web3'
import Web3Modal from "web3modal";
import lemon_logo from './images/headhd.png';
import cashh from './images/cashh.png';
import './App.css';
import Lemons from './abis/Lemons.json'
import { providerOptions } from "./providerOptions";

const Loader = () => (
  <div class="divLoader">
    <svg class="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
      <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#FFFFFF" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
    </svg>
  </div>
);


class App extends Component {
  
  ethEnabled = async () => {
    let contract = ""
    this.setState({statusUpdate: 'Connecting to wallet...'})
    this.setState({isError: false, errorMessage: ''})

    try {

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions // required
      });
      
      const provider = await web3Modal.connect()
      
      const web3 = new Web3(provider)
      this.setState({ web3: web3})
      const networkId = await web3.eth.net.getId()
      const accounts = await web3.eth.getAccounts()
      console.log(accounts[0])

      if (networkId === 1) {
        const abi = Lemons.abi

        contract = new web3.eth.Contract(abi, '0x0b22fe0a2995c5389ac093400e52471dca8bb48a')
        this.setState({ contract })

      } else {
        this.setState({statusUpdate: 'Error'})
        this.setState({isError: true, errorMessage: 'Switch to the Ethereum Network'})
      }

      if (accounts.length) {
        this.setState({statusUpdate: 'Connected to ' + accounts[0].substring(0, 5) + '....' + accounts[0].substring(accounts[0].length - 5)})
    
        const count = await contract.methods.balanceOf(accounts[0]).call()
        console.log(count)

        if (count > 0) {
          this.setState({ account: accounts[0]})
        } else {
          this.setState({statusUpdate: 'Error'})
          this.setState({isError: true, errorMessage: "You don't own any Lemoons!"})
        }
 
      } else {
        this.setState({statusUpdate: 'Error'})
        this.setState({isError: true, errorMessage: 'There are no accounts in your wallet.'})
      }

    } catch (error) {
      console.log(error)
      this.setState({statusUpdate: 'Error'})
      this.setState({isError: true, errorMessage: 'There was an error connecting to your wallet'})
    }
  }

  getPasscode = async (signature) => {
    this.setState({statusUpdate: 'Getting your passcode...'})
    this.setState({ loading: true})
    try {
      fetch('https://us-central1-little-lemon-friends.cloudfunctions.net/lemon-passcoder', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({signature: signature})
      })
      .then((response) => response.json())
      .then((actualData) => {
        console.log(actualData)
        if (actualData.passcode) {
          this.setState({passcodeButtonText: actualData.passcode})
          this.setState({statusUpdate: 'Passcode Found!'})
          this.setState({statusUpdate: 'Your 1 time use passcode!!'})
        } else {
          this.setState({statusUpdate: actualData.status})
          this.setState({statusUpdate: 'Error'})
          this.setState({isError: true, errorMessage: actualData.status})
        }
        this.setState({ loading: false})
      })
      .catch((err) => {
        console.log(err.message);
      })
    } catch  (error)  {
      console.log('Contract Call Did not work')
    }
  }

  mint = async () => {
    this.setState({statusUpdate: 'Sign the message to get your passcode!'})
    this.setState({isError: false, errorMessage: ''})
    let signature = ''
    let message = "Get Passcode!"

    signature = await this.state.web3.eth.personal.sign(message, this.state.account)

    this.getPasscode(signature)
    console.log('signature ' , signature)
  }

  onClickSavePasscode(){
    navigator.clipboard.writeText(this.state.passcodeButtonText)
  }

  onHandleClickStore() {
    window.location.assign('https://shop.littlelemonfriendsnft.com/');
  }
 
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      web3: '',
      signature: '',
      account: '',
      contract: null,
      totalSupply: 0, 
      isError: false,
      errorMessage: '',
      statusUpdate: '',
      passcodeButtonText: ''
    }
  }

  render() {
    return (
      <div className="App">
        <div className='header'>
          <img src={lemon_logo} class="lemon-img"></img>
          <a href="https://www.littlelemonfriendsnft.com" className="brand w-nav-brand">
            <div className="text-block-2">Little Lemon Friends</div>
          </a>
        </div>

        <div className="body">
          <div className="top-body">
            <h1 className="large-heading">LE-MERCH</h1>
            <h3 className="small-heading">Holder Verification Page</h3>

            <div className="body-display large">
              <strong className="bold-text">

                <br/><br/><br/>
                { this.state.account && 
                  <div><span className="error-text">STATUS: {this.state.statusUpdate}</span></div>
                }

              </strong>
            </div>
          </div>

          {this.state.loading ? <Loader /> : null}  
          <div className="middle-body">

            <div className="child-middle-body">
              <img src={cashh} class="desk-img"></img>
            </div>

            <div className="child-middle-body">
              
              { !this.state.account &&
                <form onSubmit={(event) => {
                  event.preventDefault()
                  this.ethEnabled()
                }}>
                  <input 
                    type='submit'
                    className="button connect-button"
                    value="Connect Wallet"
                  />
                </form>
              }
              { this.state.account && !this.state.passcodeButtonText &&
                <form onSubmit={(event) => {
                  event.preventDefault()
                  this.mint()
                }}>
                  <input
                    type='submit'
                    className="button connect-button"
                    value="Get Passcode"
                  />
                </form>
              }

              { this.state.passcodeButtonText &&
                  <input
                    type="text"
                    className="pass-code-input"
                    value={this.state.passcodeButtonText}
                  />
              }

              <br></br>

              { this.state.isError &&
                <div className="error">{ this.state.errorMessage }</div>
              }
              <br></br>

              { this.state.account && !this.state.passcodeButtonText &&
                <button 
                type='submit'
                className="button enter-shop-button"
                value="Connect Wallet"
                >Enter Shop &gt;</button>
              }

              { this.state.account && this.state.passcodeButtonText &&
                <button 
                  type='submit'
                  className="button enter-shop-button-open"
                  value="Connect Wallet"
                  onClick={this.onHandleClickStore}
                >Enter Shop &gt;</button>
              }
            </div>
          </div>

        </div>
      </div>
    )
  };
}

export default App;
